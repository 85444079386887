import React from 'react';
import { Typography, Button, Box, Stack, Card, CardContent, Grid, useMediaQuery } from '@mui/material';
import bg from '../assets/svg/bg.svg';
import doc from '../assets/svg/document.svg';
import wallet from '../assets/svg/wallet.svg';
import fileTick from '../assets/svg/fileTick.svg';
import homeicon from '../assets/images/HomeIcon.png';
import building from '../assets/svg/building.svg';
import truckIcon from '../assets/svg/truckIcon.svg';
import employee from '../assets/svg/employee.svg';
import BgImg from '../components/bgImg';
import Faq from '../components/faq';
import testimonial from '../assets/images/testimonial-bg.png';

import deliveryboy from '../assets/images/deliveryboy1_1-removebg-preview 1.png';
import Benefitmobile from '../components/benifitmoile';
import MobilePartner from '../components/MobilePartner';
import process1 from '../assets/images/process2.png';
import union from '../assets/images/Union.png';
import unionleft from '../assets/images/Unionleft.png';
import uniondown from '../assets/images/Uniondown.png';
import HeaderHomepage from '../components/HeaderHomepage';
import Feedback from '../components/Feedback';
import Footer from '../components/Footer';

function MobileHome() {

    const isMatch1 = useMediaQuery("(max-width:400px)")
    return (
        <>
            <BgImg>
                <HeaderHomepage />
                <Stack direction={"column"} >
                    <Typography sx={{ fontFamily: "Poppins", color: "#5C21DB", fontSize: '18.5px', fontWeight: '500', marginLeft: '141px', marginTop: "30px" }}>Our Services</Typography>
                    <Typography sx={{ fontFamily: "Rubik", color: "#000", fontSize: '14px', marginLeft: '34px', fontWeight: '400', alignItems: 'center' }}>
                        Reliable and Efficient Shipment Booking and Tracking  Services for Next Gen</Typography>
                    <Typography sx={{ fontFamily: 'Rubik', fontSize: '14px', fontWeight: 500, marginLeft: '14px', marginTop: '20px', marginRight: '15px', marginBottom: '50px' }}>
                        DLX team of logistics experts provides an integrated solution for all your logistics needs. We leverage the latest technology to provide real-time tracking and updates for your shipments,
                        so you always know where your goods are in transit. You can book, manage, and track your shipments from anywhere, at any time,
                        through our user-friendly mobile app.</Typography>
                </Stack>


                {/* //<Grid lg={12} container spacing={1} > */}
                <Stack direction={"row"} >

                    <Card sx={{ backgroundColor: '#5822DE', color: 'white', p: 1, width: '115px', height: '115px', borderRadius: '0px', ml: 6 }} >
                        {/* <Box component="img" alt="bg" src={bg} sx={{ paddingTop:  '40px', position: 'absolute' }} /> */}

                        <Box component="img" alt="arrow" src={doc} sx={{ height: '40px', width: '40px', paddingLeft:isMatch1?'32px': '38px', pt: 1 }} />
                        <CardContent>
                            <Typography sx={{ fontFamily: "Rubik", fontSize: isMatch1 ? '10px' : '12px' }}>Easy&nbsp;Shipment <br />&emsp;&nbsp;&nbsp;Booking</Typography>
                        </CardContent>
                    </Card>


                    <Box component="img" src={union} alt="truck" width={'15%'} height={'10px'} mt={10} ml={0} />

                    <Card sx={{ backgroundColor: '#461BB1', color: 'white', p: 1, width: '115px', height: '115px', borderRadius: '0px', mr: 3 }}>
                       {/* <Box component="img" alt="bg" src={bg} sx={{ paddingTop:  '40px', position: 'absolute' }} /> */}


                        <Box component="img" alt="arrow" src={wallet} sx={{ height: '40px', width: '40px', paddingLeft:isMatch1?'32px': '38px', pt: 1 }} />
                        <CardContent>
                            <Typography sx={{ fontFamily: "Rubik", fontSize:isMatch1 ? '10px' : '12px', paddingTop: '0px' }}>&nbsp;&nbsp;&nbsp;Competitive &nbsp;&nbsp;&nbsp;&emsp;Pricing</Typography>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack>
                    <Box component="img" src={uniondown} alt="truck" width={'10px'} height={'5%'} ml={isMatch1 ? 33 : 40} /></Stack>

                <Stack direction={"row"} mt={0}>
                    <Card sx={{ backgroundColor: '#12013C', color: 'white', p: 1, width: '115px', height: '115px', borderRadius: '0px', ml: 6 }}>
                        {/* <Box component="img" alt="bg" src={bg} sx={{ paddingTop: '40px', position: 'absolute' }} /> */}

                        <Box component="img" alt="arrow" src={fileTick} sx={{ height: '40px', width: '40px', paddingLeft:isMatch1?'32px': '38px', pt: 1 }} />
                        <CardContent>
                            <Typography sx={{ fontFamily: "Rubik", fontSize:isMatch1 ? '10px' : '12px' }}>&emsp; &nbsp;Schedule <br />  &nbsp;&nbsp;&nbsp;&emsp;Delivery</Typography>
                        </CardContent>
                    </Card>

                    <Box component="img" src={unionleft} alt="truck" width={'15%'} height={'10px'} marginLeft={0} mt={10} />
                    <Card sx={{ backgroundColor: '#300C8A', color: 'white', p: 1, width: '115px', height: '115px', borderRadius: '0px', mr: 3 }}>
                        {/* <Box component="img" alt="bg" src={bg} sx={{ paddingTop: '40px', position: 'absolute' }} /> */}

                        <Box component="img" alt="arrow" src={homeicon} sx={{ height: '40px', width: '40px', paddingLeft:isMatch1?'32px': '38px', pt: 1 }} />
                        <CardContent>
                            <Typography sx={{ fontFamily: "Rubik", fontSize:isMatch1 ? '10px' : '12px', }}>&nbsp;&nbsp;Personalized &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Support</Typography>
                        </CardContent>
                    </Card>

                </Stack>
                {/* </Grid> */}

                <Benefitmobile />
                <Grid container spacing={2} mt={1} >
                    <Grid item xs={12}>
                        <Stack
                            style={{ backgroundColor: '#ECEDEF', backgroundSize: 'cover', backgroundPosition: 'absolute', padding: '10px', }} >
                            <Stack>
                                <Typography
                                    sx={{ fontSize: '18px', fontFamily: 'Poppins', fontWeight: '800', textAlign: 'center', marginTop: '20px', }} >
                                    Our Streamlined Process
                                </Typography>
                                <Typography
                                    sx={{ fontSize: '12px', fontFamily: 'Poppins', textAlign: 'center', margin: '20px 0', }}>
                                    Our platform is designed with the latest tools and technologies,
                                    ensuring that every shipment is handled with care and delivered
                                    with speed.So whether you're a consignor, consignee, or truck
                                    operator, our logistics solutions provide the competitive edge you
                                    need to succeed.
                                </Typography>
                                <Stack direction={'row'} justifyContent="center">
                                    <Box component='img' src={process1} sx={{ maxWidth: '100%', height: 'auto', mt: -2.5 }} />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>

                <Stack direction="row" padding={0}>
                    <Grid item xs={12} md={4}>
                        <Stack direction="column" sx={{ backgroundColor: '#5822DE', width:isMatch1?'110px': '120px', height:isMatch1?'89px': '109px', marginLeft: '2px', marginTop: '40px', paddingTop: '40px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box component="img" src={building} alt="building" width={'25px'} height={'25px'} textAlign={'center'} marginTop={isMatch1?-2:0} />
                            </Box>
                            <Typography sx={{ color: '#fff', fontSize:isMatch1?'14px': '20px', fontFamily: 'Rajdhani', textAlign: 'center' }}>50+</Typography>
                            <Typography sx={{ color: '#fff', fontSize: '12px', fontFamily: 'Rubik', textAlign: 'center' }}>Office <br />Network</Typography>
                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <Stack direction="column" sx={{ backgroundColor: '#5822DE', marginTop: '40px', marginLeft: isMatch1 ? '8px' : '10px', width:isMatch1?'110px': '120px', height:isMatch1?'89px': '109px', paddingTop: '40px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box component="img" src={truckIcon} alt="truck" width={'25px'} height={'25px'} textAlign={'center'} marginTop={isMatch1?-2:0}/>
                            </Box>
                            <Typography sx={{ color: '#fff', fontSize:isMatch1?'14px': '22px', fontFamily: 'Rajdhani', textAlign: 'center' }}>306</Typography>
                            <Typography sx={{ color: '#fff', fontSize: '12px', fontFamily: 'Rubik', textAlign: 'center' }}>Goods Transport Vehicle</Typography>
                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <Stack direction="column" sx={{ backgroundColor: '#5822DE', marginTop: '40px', marginLeft: isMatch1 ? '8px' : '10px', width:isMatch1?'110px': '120px', height:isMatch1?'89px': '109px', paddingTop: '40px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box component="img" src={employee} alt="employee" width={'25px'} height={'25px'} textAlign={'center'} marginTop={isMatch1?-2:0}/>
                            </Box>
                            <Typography sx={{ color: '#fff', fontSize:isMatch1?'14px': '22px', fontFamily: 'Rajdhani', textAlign: 'center' }}>206+</Typography>
                            <Typography sx={{ color: '#fff', fontSize: '12px', fontFamily: 'Rubik', textAlign: 'center' }}>Employee<br /> Strength</Typography>
                        </Stack>
                    </Grid>
                </Stack>

                <Grid container spacing={2} padding={5}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <img
                            src={deliveryboy}
                            style={{ width: '100%', maxWidth: '80%', height: 'auto', marginBottom: '20px', marginLeft: '30px', marginTop: '50px' }}
                            alt="Contact Image"
                        />

                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Stack >
                            <Typography sx={{ mb: 4, fontFamily: 'Rajdhani', fontSize: '22px', fontWeight: '600', color: 'blue', marginLeft: '20px', marginTop: '16px' }}>
                                Frequently Asked Questions
                            </Typography>

                            <Faq />
                        </Stack>
                    </Grid>
                </Grid>



                <Stack direction={{ xs: 'column', md: 'row' }} marginTop={{ xs: '10px', md: '50px' }} >
                    <Stack width={{ xs: '100%', md: '100%' }} marginLeft={{ xs: '0', md: '100px' }}>
                        <Typography sx={{ fontSize: '18px', fontFamily: 'Poppins', fontWeight: '600', marginLeft: '140px' }}>Our Partners</Typography>
                        <Typography sx={{ fontSize: '16px', fontFamily: 'Inter', marginTop: '20px', marginLeft: '14px', marginBottom: '20px' }}>Our partners are an essential part of our success, and we are always looking for new opportunities to expand our network.</Typography>
                    </Stack>
                </Stack>
                <MobilePartner />

                <Stack style={{ backgroundImage: `url(${testimonial})`, backgroundSize: 'cover', }}>
                    <Typography sx={{ fontSize: '18px', fontFamily: 'Poppins', fontWeight: '600', textAlign: 'center', marginTop: '80px', marginBottom: '10px' }}>
                        Client's Feedback
                    </Typography>
                    <Feedback />
                </Stack>

                <Footer />
            </BgImg>
        </>
    );
}

export default MobileHome;