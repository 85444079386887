import React, { useState } from 'react';
import { Typography, Button, Box, Stack, useMediaQuery, ToggleButtonGroup, InputAdornment, ToggleButton } from '@mui/material';
import CostEsti from '../assets/images/costestimationheader.png';
import CostEstimobile from '../assets/images/costestimationheader2.png';

import logo from '../assets/svg/logo.svg';
import backgroundNew from '../assets/images/backgroundNew.png';
import LoadingScreen from '../components/loadingScreen';
import { styled } from '@mui/material/styles';
import SelectTrkType from '../components/SelectTrkType';
import CtrlFillLabelTxtField from '../components/CtrlFillLabelTxtField';
import PlaceAutofill from '../components/PlaceAutofill';
import { customAlert } from '../components/notify';
import { calculateRate } from '../services/cust-service';
import vector from '../assets/images/Vector1.png';
import Footer from '../components/Footer';

import { useNavigate } from 'react-router-dom';
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: 10,
    },
    '&:first-of-type': {
      borderRadius: 10,
    },
  },
}));

export default function CostEstimation() {
  const isMatch = useMediaQuery("(min-width: 650px)");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selection, setSelection] = useState({
    weightUnit: "ton",
    trkTypeObj: null, fromId: "", toId: "",
    from: "", to: "", weight: null, ans: null
  });
  const [formErrors, setFormErrors] = useState({});
  const [trkTypeTxt, setTrkTypeTxt] = useState("");

  const [selTrkTyp, setSelTrkTyp] = useState(false);
  const handleSelTrkTypOpen = () => setSelTrkTyp(true);
  const handleSelTrkTypClose = () => setSelTrkTyp(false);

  function handleFromIdChange(value) {
    setSelection(prevSel => { return { ...prevSel, fromId: value?.placeId, from: value?.place } });

  }

  function handleToIdChange(value) {
    setSelection(prevSel => { return { ...prevSel, toId: value?.placeId, to: value?.place } })
  }

  function handleTgWeightUnitChange(ele, v) {
    if (v == null)
      return

    setSelection((e) => { return { ...e, weightUnit: v } });
  }

  function handleSetTrkType(trkTypeObj) {
    setSelection((e) => { return { ...e, trkTypeObj: trkTypeObj } });
    let strg = getTruckName(trkTypeObj);
    setTrkTypeTxt(strg)
  }

  function getTruckName(obj) {
    // obj = JSON.parse(obj);
    let strg = "";
    for (var key in obj) {
      if (key.startsWith("c_") || key.startsWith("b_"))
        continue;
      strg += obj[key] + ",  "
    }
    return strg;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const inputObject = Object.fromEntries(formData);

    if (selection.weightUnit === "kg") {
      inputObject.weightUnit = "ton";
      inputObject.weight = parseFloat(inputObject.weight) / 1000;
    } else {
      inputObject.weightUnit = selection.weightUnit;
    }

    let errorsObj = validate({ values: inputObject, truckObj: selection.trkTypeObj });
    setFormErrors(errorsObj);

    if (Object.keys(errorsObj).length > 0)
      return;

    setLoading(true);

    try {
      const inputData = {
        from: selection.from,
        to: selection.to,
        fromId: selection.fromId,
        toId: selection.toId,
        truckType: selection.trkTypeObj,
        weight: selection.weight,
        weightUnit: selection.weightUnit
      }

      const ans = await calculateRate(inputData);
      setSelection(prevSel => { return { ...prevSel, ans: ans } })

      setLoading(false);
    } catch (err) {
      console.log(err);
      customAlert(err)
    }
    setLoading(false);
  };

  function handleTxtFieldChange(e) {
    const { name, value } = e.target;
    setSelection(prevSel => { return { ...prevSel, [name]: value } })
  }

  function getLocalStrg(value) {
    try {
      value = parseFloat(value)
      return value.toLocaleString('en-IN')
    } catch (error) {
      return ""
    }

  }

  return (<>
    {loading === false ? <form onSubmit={handleSubmit} noValidate id='addBo' style={{ padding: 0, height: "100%" }}>
      <Box position={'relative'}>
        <Stack style={{ backgroundImage: `url(${backgroundNew})`, backgroundSize: 'cover', backgroundPosition: 'absolute', top: 150 }}>


          <Stack display={{ xs: 'none', lg: 'flex' }} style={{ backgroundImage: `url(${CostEsti})`, backgroundSize: 'cover', backgroundPosition: 'absolute', width: isMatch ? '1420px' : '395px', height: isMatch ? '380px' : '170px' }}>
            {/* <Typography sx={{ fontSize: isMatch ? 50 : 22, color: "#fff", pt: isMatch ? 35 : 15, pl: isMatch ? 5 : 2, fontWeight: isMatch ? 600 : 700, fontFamily: 'Rajdhani' }}>Cost Estimation</Typography> */}

          </Stack>

          <Stack display={{ xs: 'flex', lg: 'none' }} style={{ backgroundImage: `url(${CostEstimobile})`, backgroundSize: 'cover', backgroundPosition: 'absolute', width: isMatch ? '1420px' : '395px', height: isMatch ? '380px' : '170px' }}>
            <Typography sx={{ fontSize: isMatch ? 50 : 22, color: "#fff", pt: isMatch ? 35 : 15, pl: isMatch ? 5 : 2, fontWeight: isMatch ? 600 : 700, fontFamily: 'Rajdhani' }}>Cost Estimation</Typography>
          </Stack>

          <Stack width={"100%"} alignItems={"center"} mt={5}>
            <Stack direction={'row'} alignItems={"center"} gap={2}>
              <Typography sx={{ fontSize: { xs: '16px', md: '30px' }, fontFamily: 'Poppins', fontWeight: '500' }}>Calculate</Typography>
              <Box component="img" alt="Company Logo" src={logo} sx={{ height: isMatch ? '30px' : '24px', width: isMatch ? '100px' : '55px' }} />
              <Typography sx={{ fontSize: { xs: '16px', md: '30px' }, fontFamily: 'Poppins', fontWeight: '500' }}>Shipping Rate</Typography>
            </Stack>
          </Stack>

          <Stack width={"100%"} alignItems={"center"} mt={2} >
            <Stack width={"100%"} alignItems={"center"} mb={2} gap={1}>
              <PlaceAutofill value={selection.from} name="from" title={"Pickup Location"} onSelect={handleFromIdChange} errMsg={formErrors.from} width={isMatch ? "40%" : "350px"} startIcColor={'green'} />
              <PlaceAutofill value={selection.to} name="to" title={"Delivery Location"} onSelect={handleToIdChange} errMsg={formErrors.to} width={isMatch ? "40%" : "350px"} startIcColor={'red'} />
            </Stack>
            <Stack width={isMatch ? "40%" : "268px"} mb={0.8} mt={3}>
              <Typography sx={{ fontWeight: "500", fontSize: isMatch ? 14 : 10, fontFamily: 'Poppins', marginLeft: isMatch ? 0 : -5 }}>TELL US MORE ABOUT YOUR SHIPMENT</Typography>
            </Stack>
            <CtrlFillLabelTxtField value={selection.weight} onChange={handleTxtFieldChange} name={"weight"} title={"Package Weight"} errMsg={formErrors.weight} type="number" step={0.01} width={isMatch ? "40%" : "350px"}
              endAdornment={(
                <InputAdornment position="end">
                  <StyledToggleButtonGroup color="primary" size="small" value={selection.weightUnit} exclusive onChange={handleTgWeightUnitChange} sx={{ height: 34, mt: 2 }}>
                    <ToggleButton value="kg">
                      <Typography sx={{
                        fontSize: isMatch ? 14 : 10,
                        fontWeight: isMatch ? 600 : 400,
                        fontFamily: 'Poppins'
                      }}>Kg</Typography>
                    </ToggleButton>
                    <ToggleButton value="ton">
                      <Typography sx={{
                        fontSize: isMatch ? 14 : 10,
                        fontWeight: isMatch ? 600 : 400,
                        fontFamily: 'Poppins'
                      }}>Ton(s)</Typography>
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </InputAdornment>
              )}
              placeholder={"Enter package weight..."} min={0}
            />
            <Stack direction={"row"} justifyContent={"center"} onClick={handleSelTrkTypOpen} width={"100%"} mt={1.5}>
              <CtrlFillLabelTxtField value={trkTypeTxt} readOnly={true} name="preTruckTypes" title={"Select Truck Type"} errMsg={formErrors.preTruckTypes} width={isMatch ? "40%" : "350px"} />
            </Stack>
            <Button variant="contained" type='submit' sx={{ mb: 2, mt: 2, width: isMatch ? 140 : 118, height: isMatch ? 45 : 32, fontSize: isMatch ? 18 : 12, fontWeight: 400, fontFamily: 'Poppins' }}>Show Rates</Button>
          </Stack>

          {selection?.ans && <Stack width={"100%"} alignItems={isMatch ? "center" : "left"} ml={isMatch ? 0 : 4} >
            <Stack width={isMatch ? "40%" : "163px"} alignItems={"start"} mt={2} >
              <Typography sx={{ fontSize: isMatch ? 14 : 10, fontWeight: 400, fontFamily: 'Inter' }}>Amounts are Shown in Rupees</Typography>
              <Box bgcolor={"secondary.main"} height={1.3} width={isMatch ? "100%" : "200%"} />
              <Stack width={"100%"} direction={"row"} alignItems={isMatch ? "center" : "left"} justifyContent={"space-between"} my={2.5} >
                <Typography sx={{ fontSize: isMatch ? 20 : 12, fontWeight: 600, fontFamily: 'Inter' }}>ESTIMATED&nbsp;PRICE:</Typography>
                <Box bgcolor={"primary.main"} sx={{
                  borderRadius: 1, height: isMatch ? 40 : 23, px: 2, display: 'flex',
                  justifyContent: 'center', width: isMatch ? '150px' : '60px',
                  alignItems: 'center', minWidth: isMatch ? 100 : 55, color: "white", ml: isMatch ? 0 : 13
                }}>
                  <Typography fontWeight={"700"} fontSize={isMatch ? 22 : 12} fontFamily={'Inter'} ><span name="Rs">&#8377;</span> {getLocalStrg(selection?.ans)}</Typography>
                </Box>
              </Stack>
              <Box bgcolor={"secondary.main"} height={1.3} width={isMatch ? "100%" : "200%"} />
              <Typography mt={1} fontSize={isMatch ? 12 : 10} color={"red"} fontFamily={'Inter'} fontWeight={300} width={isMatch ? "100%" : "200%"}>*The rates shown here may differ from the actual charges for your shipment. Feel free to reach out to us for more details.</Typography>
            </Stack>
          </Stack>}


          <Stack style={{ backgroundSize: '100% 100%', backgroundImage: `url(${vector})`, backgroundPosition: 'absolute', marginTop: '12px' }}>
            <Typography sx={{ fontSize: isMatch ? "25px" : "14px", color: "#fff", textAlign: 'center', paddingTop: isMatch ? '55px' : '30px', fontFamily: 'Poppins', fontWeight: isMatch ? 500 : 500, }}>
              DLX helps you get<br /> <span style={{ color: '#E11E31', fontSize: isMatch ? '30px' : '14px' }}>[</span>
              <span style={{ fontSize: isMatch ? '30px' : '14px' }}> Instant Quote and Guaranteed Saving </span>
              <span style={{ color: '#E11E31', fontSize: isMatch ? '25px' : '14px' }}>]</span> 
              <br />for your shipments, all in one place </Typography>
            <Button onClick={() => navigate("/contact")} variant="contained" sx={{
              background: "#E11E31", color: "#fff", width: isMatch ? '173px' : '119px',
              height: isMatch ? '46px' : '25px', borderRadius: '25px', textTransform: "none", display: "flex", alignItems: "center",
              gap: 4, marginTop: { xs: '25px', md: '30px' }, marginLeft: { xs: '130px', md: '600px' }, fontSize: { xs: '10px', md: '16px' },
              fontFamily: 'Poppins', fontWeight: '500', textAlign: 'center', marginBottom: '40px'
            }}>
              JOIN WITH US
            </Button>
          </Stack>
          <Stack style={{ marginTop: isMatch ? '-15px' : '-20px' }}>
            <Footer />
          </Stack>

        </Stack>
      </Box>
      <SelectTrkType handleClose={handleSelTrkTypClose} open={selTrkTyp} handleSetTrkType={handleSetTrkType} />
    </form> : <LoadingScreen />}</>
  );

}

function validate({ values, truckObj }) {
  const errors = {};

  if (!values.from)
    errors.from = "Pickup Location is required!";

  if (!values.to)
    errors.to = "Delivery Location is required!";

  if (!values.weight)
    errors.weight = "Weight is required!";

  if (!values.preTruckTypes)
    errors.preTruckTypes = "Truck Type is required!";
  else {
    const trkObjLength = Object.keys(truckObj).length;
    if (trkObjLength !== 7)
      errors.preTruckTypes = "Select Full Truck Type";
  }

  return errors;
};