import React from "react";
import {
  Typography,
  Box,
  Stack,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import bg from "../assets/svg/bg.svg";
import doc from "../assets/svg/document.svg";
import wallet from "../assets/svg/wallet.svg";
import fileTick from "../assets/svg/fileTick.svg";
import homeicon from "../assets/images/HomeIcon.png";
import Benefit from "../components/benefit";
import building from "../assets/svg/building.svg";
import truckIcon from "../assets/svg/truckIcon.svg";
import employee from "../assets/svg/employee.svg";
import BgImg from "../components/bgImg";
import process1 from "../assets/images/process1.png";
import Faq from "../components/faq";
import testimonial from "../assets/images/testimonial-bg.png";
import deliveryboy from "../assets/images/deliveryboy1_1-removebg-preview 1.png";
import MobileHome from "./mobileHome";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Footer from "../components/Footer";
import Feedback from "../components/Feedback";
import HeaderHomepage from "../components/HeaderHomepage";
import Partners from "../components/Partners";
function HomePage() {
  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const theme = useTheme();
  const isMatch = useMediaQuery("(min-width: 650px)");
  return (
    <>
      {!isMatch ? (
        <>
          <MobileHome />
        </>
      ) : (
        <BgImg>
          <Stack

          >
            <HeaderHomepage />
          </Stack>
          <Stack
            direction={"column"}
            marginTop={"4.625rem"}
            marginLeft={"3.2rem"}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "#520AF8",
                fontSize: "40px",
                fontWeight: "500",
              }}
            >
              Our Services
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "#000",
                fontSize: "25px",
                marginRight: "37.5rem",
                fontWeight: "400",
              }}
            >
              Reliable and Efficient Shipment Booking and Tracking Services for
              Next Gen
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            marginTop={"30px"}
            marginLeft={"50px"}
            marginRight={"100px"}
          >
            <Grid lg={12} container spacing={5}>
              <Grid item lg={3} xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: "#5822DE",
                    color: "white",
                    p: 2,
                    width: "323.7px",
                    height: "263.41px",
                    borderRadius: "0px",
                  }}
                >
                  <Box
                    component="img"
                    alt="bg"
                    src={bg}
                    sx={{
                      paddingTop: "10px",
                      position: "absolute",
                    }}
                  />

                  <Box
                    component="img"
                    alt="arrow"
                    src={doc}
                    sx={{
                      height: "70px",
                      width: "70px",
                      paddingTop: "30px",
                      paddingLeft: "9px",
                    }}
                  />
                  <CardContent>
                    <Typography sx={{ fontFamily: "Rajdhani", fontSize: "30px" ,fontWeight:600}} >
                      Easy Shipment<br/> Booking
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={3} xs={12} sm={6} marginLeft={"0px"}>
                <Card
                  sx={{
                    backgroundColor: "#461BB1",
                    color: "white",
                    p: 2,
                    width: "323.7px",
                    height: "263.41px",
                    borderRadius: "0px",
                  }}
                >
                  <Box
                    component="img"
                    alt="bg"
                    src={bg}
                    sx={{
                      paddingTop: "10px",
                      paddingLeft: "0px",
                      position: "absolute",
                    }}
                  />

                  <Box
                    component="img"
                    alt="arrow"
                    src={wallet}
                    sx={{
                      height: "70px",
                      width: "70px",
                      paddingTop: "30px",
                      paddingLeft: "9px",
                    }}
                  />
                  <CardContent>
                    <Typography
                      sx={{ fontFamily: "Rajdhani", fontSize: "30px",fontWeight:600 }}
                    >
                      Competitive
                      <br /> Pricing
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={3} xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: "#300C8A",
                    color: "white",
                    p: 2,
                    width: "323.7px",
                    height: "263.41px",
                    borderRadius: "0px",
                  }}
                >
                  <Box
                    component="img"
                    alt="bg"
                    src={bg}
                    sx={{
                      paddingTop: "10px",
                      paddingLeft: "0px",
                      position: "absolute",
                    }}
                  />

                  <Box
                    component="img"
                    alt="arrow"
                    src={homeicon}
                    sx={{
                      height: "70px",
                      width: "70px",
                      paddingTop: "30px",
                      paddingLeft: "9px",
                    }}
                  />
                  <CardContent>
                    <Typography
                      sx={{ fontFamily: "Rajdhani", fontSize: "30px",fontWeight:600 }}
                    >
                      Personalized <br/>Support
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={3} xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: "#12013C",
                    color: "white",
                    p: 2,
                    width: "323.7px",
                    height: "263.41px",
                    borderRadius: "0px",
                  }}
                >
                  <Box
                    component="img"
                    alt="bg"
                    src={bg}
                    sx={{
                      paddingTop: "10px",
                      paddingLeft: "0px",
                      position: "absolute",
                    }}
                  />

                  <Box
                    component="img"
                    alt="arrow"
                    src={fileTick}
                    sx={{
                      height: "70px",
                      width: "70px",
                      paddingTop: "30px",
                      paddingLeft: "9px",
                    }}
                  />
                  <CardContent>
                    <Typography
                      sx={{ fontFamily: "Rajdhani", fontSize: "30px",fontWeight:600 }}
                    >
                      Schedule <br />
                      Delivery
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Stack>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "18px",
              marginLeft: "50px",
              marginTop: "50px",
              marginRight: "50px",
              marginBottom: "50px",
            }}
          >
            DLX team of logistics experts provides an integrated solution for
            all your logistics needs. We leverage the latest technology to
            provide real-time &emsp;&emsp;&emsp;tracking and updates for your
            shipments, so you always know where your goods are in transit. You
            can book, manage, and track your
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;shipments
            from anywhere, at any time, through our user-friendly mobile app.
          </Typography>

          <Benefit marginLeft={"30px"} marginRight={"30px"} />
          {/* <Grid container spacing={1} backgroundColor={"#ECEDEF"} mt={5}>
          <Stack style={{marginLeft:100, backgroundImage: `url(${process1})`, backgroundSize: '100% 100%', backgroundPosition: 'center', width: isMatch ? '1200px' : '400px', height: isMatch ? '1060px' : '170px' }}> */}
          {/* <Typography sx={{ fontSize: isMatch ? "50px" : "22px", color: "#fff", justifyContent: "left", pt: isMatch ? 40 : 15, paddingLeft: isMatch ? "100px" : "20px", fontFamily: 'Rajdhani', fontWeight: isMatch ? 600 : 700 }}>Services</Typography> */}
          <Grid container spacing={2} mt={5} >
            <Grid item  xs={12}>
              <Stack
                direction={"column"}
                style={{
                 // backgroundColor: "#ECEDEF",
                 backgroundColor: "#eeeeee",
                  backgroundSize: "cover",
                  backgroundPosition: "absolute",
                  padding: "20px",
                }}
              >
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "38px",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    Our Streamlined Process
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        textAlign: "center",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    >
                      Our platform is designed with the latest tools and
                      technologies, ensuring that every shipment is handled with
                      care and delivered with speed. So whether you're a
                      consignor, consignee, or truck operator, our logistics
                      solutions provide the competitive edge you need to
                      succeed.
                      <Stack justifyContent="center">
                        <Box
                          component="img"
                          src={process1}
                          sx={{ ml: {md:25,xs:8}, mt: 0, width: {md:"1000px",xs:"600px"} ,borderRadius:5}}
                        />
                      </Stack>{" "}
                    </Typography>{" "}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Stack direction={"row"} display={"flex"}>
            <Stack
              direction="column"
              sx={{
                height: "250px",
                width: "350px",
                backgroundColor: "#5822DE",
                marginTop: "40px",
                marginLeft: 8,
                paddingTop: "80px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  component="img"
                  src={building}
                  alt="building"
                  width={"50px"}
                  height={"50px"}
                  textAlign={"center"}
                />
              </Box>
              <Typography
                sx={{
                  paddingTop: "30px",
                  color: "#fff",
                  fontSize: "35px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                50+
              </Typography>
              <Typography
                sx={{
                  paddingTop: "30px",
                  color: "#fff",
                  fontSize: "26px",
                  fontFamily: "Rubik",
                  textAlign: "center",
                }}
              >
                Office Network
              </Typography>
            </Stack>

            <Stack
              direction="column"
              sx={{
                height: "250px",
                width: "350px",
                backgroundColor: "#5822DE",
                marginTop: "40px",
                marginLeft: 15,
                paddingTop: "80px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  component="img"
                  src={truckIcon}
                  alt="truck"
                  width={"50px"}
                  height={"50px"}
                  textAlign={"center"}
                />
              </Box>
              <Typography
                sx={{
                  paddingTop: "30px",
                  color: "#fff",
                  fontSize: "35px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                306
              </Typography>
              <Typography
                sx={{
                  paddingTop: "30px",
                  color: "#fff",
                  fontSize: "25px",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Goods Transport Vehicle
              </Typography>
            </Stack>



            <Stack
              direction="column"
              sx={{
                height: "250px",
                width: "350px",
                backgroundColor: "#5822DE",
                marginTop: "40px",
                marginLeft: 15,
                paddingTop: "80px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  component="img"
                  src={employee}
                  alt="employee"
                  width={"50px"}
                  height={"50px"}
                  textAlign={"center"}
                />
              </Box>
              <Typography
                sx={{
                  paddingTop: "30px",
                  color: "#fff",
                  fontSize: "35px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                206+
              </Typography>
              <Typography
                sx={{
                  paddingTop: "30px",
                  color: "#fff",
                  fontSize: "26px",
                  fontFamily: "Rubik",
                  textAlign: "center",
                }}
              >
                Employee Strength
              </Typography>
            </Stack>

          </Stack>

          <Grid container spacing={2} padding={5}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <img
                src={deliveryboy}
                style={{
                  width: "100%",
                  maxWidth: "75%",
                  height: "auto",
                  marginBottom: "20px",
                  marginLeft: "30px",
                  marginTop: "50px",
                }}
                alt="Contact Image"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Stack>
                <Typography
                  variant="h4"
                  sx={{
                    mb: 4,
                    fontFamily: "Poppins",
                    fontSize: "30px",
                    fontWeight: "semibold",
                    color: "blue",
                    marginLeft: "20px",
                    marginTop: "50px",
                  }}
                >
                  Frequently Asked Questions
                </Typography>
                <Stack>
                  <Faq
                    sx={{
                      marginLeft: "16px",
                      marginTop: "16px",
                      marginBottom: "16px",
                      position: "relative",
                    }}
                    style={{ top: "16px" }}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Stack
            direction={{ xs: "column", md: "row" }}
            marginTop={{ xs: "50px", md: "50px",lg: "50px" }}
            marginBottom={"50px"}
          >
            <Stack
              width={{ xs: "100%", md: "95%" }}
              marginLeft={{ xs: "0", md: "90px" }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "40px", md: "50px" },
                  fontFamily: "Poppins",
                  fontWeight: "500", fontSize: "35px",
                }}
              >
                Our Partners
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  marginTop: "15px",
                  fontWeight: "400",
                }}
              >
                Our partners are an essential part of our success, and we are
                always <br />
                looking for new opportunities to expand our network.
              </Typography>
            </Stack>
          </Stack>
          <Partners />
          <Stack
            style={{
              backgroundImage: `url(${testimonial})`,
              backgroundSize: "cover",
            }}
          >
            <Typography
              sx={{
                fontSize: "35px",
                fontFamily: "Poppins",
                fontWeight: "500",
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              Client's Feedback
            </Typography>
            <Feedback />
          </Stack>
          <Stack sx={{ marginTop: 5 }}>
            <Footer />
          </Stack>
        </BgImg>
      )}
    </>
  );
}

export default HomePage;
