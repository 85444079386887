import React from 'react';
import { Typography, Button, Box, Stack, Grid, useMediaQuery } from '@mui/material';
import footerwebsite from '../assets/images/footer.png';
import footermobile from '../assets/images/footermobile.png';
import facebook from '../assets/images/logos_facebook.png';
import twitter from '../assets/images/skill-icons_twitter.png';
import instagram from '../assets/images/skill-icons_instagram.png';
import birdimg from '../assets/images/Group.png';
import applestore from '../assets/svg/Apple.svg';
import googleplay from '../assets/images/Mask group.png';
import line from '../assets/svg/Line5.svg'
import route from '../Routes';
import { useNavigate } from 'react-router-dom';


function Footer() {

  const isMatch = useMediaQuery("(min-width:650px)")
  const isMatch1 = useMediaQuery("(max-width:400px)")
  const navigate = useNavigate();

  async function handleClickhome() {
    navigate(route.homepage, { replace: true });
    console.log("webhome")
  }
  async function handleClickservice() {
    navigate(route.servicespage, { replace: true });
    console.log("service")
  }
  async function handleClickcost() {
    navigate(route.costestimation, { replace: true });
    console.log("costestimobile")
  }
  async function handleClickabout() {
    navigate(route.aboutus, { replace: true });
    console.log("about")
  }
  async function handleClickfaq() {
    navigate(route.faq, { replace: true });
    console.log("faq")
  }
  async function handleClickcontact() {
    navigate(route.contactus, { replace: true });
    console.log("contact")
  }
  return (
    <>
      {/* <Stack display={{ xs: 'none', lg: 'flex' }} style={{ backgroundSize: 'cover', backgroundImage: `url(${footerwebsite})`, backgroundPosition: 'absolute', marginTop: '40px', }}> */}
      <Stack display={{ xs: 'none',sm:'flex', lg: 'flex',md:'flex' }} style={{ backgroundImage: `url(${footerwebsite})`, marginTop: '40px', }}>

        <Grid container spacing={0} direction={"row"}>
          <Grid item xs={12} lg={4}>
            <Stack direction="column" sx={{ marginLeft: '30px', }}>
              <Typography sx={{ marginTop: '30px', color: '#fff', fontSize: '30px', fontFamily: 'Poppins', fontWeight: '600' }}> deskCompany Information</Typography>
              <Typography sx={{ marginTop: '10px', color: '#fff', fontSize: '12.5px', fontFamily: 'Poppins', }}> DLX is India's Fastest Growing End-to-End Logistics provider that provides e-commerce,
                hyper-local, on-demand delivery solutions for businesses for seamless business operations and dynamic growth.</Typography>
            </Stack>
          </Grid>


          <Grid item xs={12} md={4}>
            <Stack direction="column" sx={{ marginLeft: '200px', paddingTop: '20px' }}>
              <Typography sx={{ color: '#fff', fontSize: '30px', fontFamily: 'Poppins', fontWeight: '600' }}> Company</Typography>
              <Stack direction="column" sx={{ alignItems: 'start', ml: 3 }}>
                <Button onClick={handleClickhome} sx={{ color: '#fff', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '20px' }}>Home</Button>
                <Button onClick={handleClickservice} sx={{ color: '#fff', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '20px' }} >Services</Button>
                <Button onClick={handleClickcost} sx={{ color: '#fff', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '20px' }} variant="text">Cost Estimation</Button>
                <Button onClick={handleClickabout} sx={{ color: '#fff', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '20px' }} variant="text">About us</Button>
                <Button onClick={handleClickfaq} sx={{ color: '#fff', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '20px' }} variant="text">FAQ&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                <Button onClick={handleClickcontact} sx={{ color: '#fff', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '20px' }} variant="text">Contact Us</Button>
              </Stack></Stack>
          </Grid>


          <Grid item xs={12} md={4}>
            <Stack direction="column" sx={{ height: '355px', marginLeft: '60px' }}>
              <Typography sx={{ paddingTop: '20px', color: '#fff', fontSize: '30px', fontFamily: 'Poppins', fontWeight: '600', fontWeight: '600' }}> Legal</Typography>
              <Stack direction="column" sx={{ alignItems: 'start', ml: 1 }}>
                <Button sx={{ color: '#fff', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '24px' }} variant="text">User License</Button>
                <Button sx={{ color: '#fff', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '24px' }} variant="text">License Agreement</Button>
                <Button sx={{ color: '#fff', fontSize: '16px', fontFamily: 'Poppins', lineHeight: '24px' }} variant="text">Privacy Policy</Button>
              </Stack></Stack>
          </Grid>
          <Box sx={{ fontSize: '30px', color: '#fff', marginTop: '-100px', ml: 7 }}>
            <Box component="img" src={line} alt="truck" width={'98%'} />
          </Box>
        </Grid>


        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Stack direction="column" sx={{ width: '424px', marginLeft: '350px', marginTop: '-60px' }}>
              <Typography sx={{ color: '#fff', fontSize: '20px', fontFamily: 'Poppins', fontWeight: '600' }}> Follow Us on</Typography>
              <Stack direction="row" sx={{ alignItems: 'start', mt: 2, ml: -2 }}>
                <Box component="img" src={facebook} alt="truck" width={'32px'} height={'31px'} mr={1} />
                <Box component="img" src={birdimg} alt="truck" width={'32px'} height={'31px'} mr={1} />
                <Box component="img" src={twitter} alt="truck" width={'32px'} height={'31px'} mr={1} />
                <Box component="img" src={instagram} alt="truck" width={'32px'} height={'31px'} mr={1} />
              </Stack>
            </Stack>
          </Grid>


          <Grid item xs={12} md={4}>
            <Stack direction="column" sx={{ marginTop: '-60px', marginBottom: 1 }}>
              <Typography sx={{ color: '#fff', fontSize: '20px', fontFamily: 'Poppins', fontWeight: '600', marginLeft: 32, }}> Flash&nbsp;App</Typography>
              <Stack direction="row" sx={{ alignItems: 'start', mt: 1, ml: 23 }}>

                <a href="https://play.google.com/store/apps/details?id=com.dlxlogistics.app">
                  <Button style={{ width: '10px', height: '56px', borderRadius: 8 }}
                    variant="text"
                    startIcon={<img src={googleplay} width={'160px'} height={'45px'} />}
                  /></a>
                <Button style={{ width: '191px', height: '56px', marginLeft: 60, borderRadius: '16px' }}
                  variant="text"
                  startIcon={<img src={applestore} width={'170px'} height={'45px'} borderRadius={'16px'} />}
                />

              </Stack></Stack>
          </Grid>
        </Grid>
      </Stack>


      {/* Footer Mobile */}
      <Stack display={{  sm: 'none' }} style={{ backgroundSize: 'cover', backgroundImage: `url(${footermobile})`, backgroundPosition: 'absolute', marginTop: '40px' }}>
        {/* <Stack display={isMatch1? "flex": "none" } style={{ backgroundSize: '100% 100%', backgroundImage: `url(${footermobile})`, backgroundPosition: 'absolute', marginTop: '40px' }}> */}
        {/* <Stack display={{ xs: 'flex', lg: 'none' }} style={{ backgroundImage: `url(${footermobile})`, backgroundSize: '100% 100%', backgroundPosition: 'center', marginTop: '40px' }}> */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Stack direction="column" sx={{ marginLeft: '30px', }}>

              <Typography sx={{ paddingTop: '5px', color: '#fff', fontSize: '16px', fontFamily: 'Poppins', fontWeight: '600' }}> Company Information</Typography>
              <Typography sx={{ paddingTop: '10px', color: '#fff', fontSize: '11px', fontFamily: 'Rubik', }}> DLX is India's Fastest Growing End-to-End Logistics provider<br /> that provides e-commerce, hyper-local,
                on-demand delivery <br />solutions for businesses for seamless business operations and <br />dynamic growth.</Typography>
            </Stack>
            <Box sx={{ fontSize: '30px', color: '#fff', marginTop: '0px', ml: 2 }}>
              <Box component="img" src={line} alt="truck" width={'95%'} height={'5px'} />
            </Box>
          </Grid>


          <Stack direction="row" >
            <Grid item xs={12} md={4}>
              <Stack direction="column" sx={{ width: '304px', height: '325px', marginLeft: '80px' }}>
                <Typography sx={{ color: '#fff', fontSize: '18px', fontFamily: 'Poppins', fontWeight: '600' }}> Company</Typography>
                <Stack direction="column" sx={{ alignItems: 'start', ml: isMatch1 ? 1 : 2 }}>
                  <Button onClick={handleClickhome} sx={{ color: '#fff', fontSize: '11px', fontFamily: 'Poppins', lineHeight: '15px' }}>Home&emsp;</Button>
                  <Button onClick={handleClickservice} sx={{ color: '#fff', fontSize: '11px', fontFamily: 'Poppins', lineHeight: '15px' }} >Services</Button>
                  <Button onClick={handleClickcost} sx={{ color: '#fff', fontSize: '11px', fontFamily: 'Poppins', lineHeight: '15px' }} >Cost Estimation</Button>
                  <Button onClick={handleClickabout} sx={{ color: '#fff', fontSize: '11px', fontFamily: 'Poppins', lineHeight: '15px' }}>About us</Button>
                  <Button onClick={handleClickfaq} sx={{ color: '#fff', fontSize: '11px', fontFamily: 'Poppins', lineHeight: '15px' }}>FAQ&emsp;&emsp;</Button>
                  <Button onClick={handleClickcontact} sx={{ color: '#fff', fontSize: '11px', fontFamily: 'Poppins', lineHeight: '15px' }} >Contact Us</Button>
                </Stack>
              </Stack>
            </Grid>

            <Stack direction="column" sx={{ alignItems: 'center' }}>
              <Typography sx={{ color: '#fff', fontSize: '18px', fontFamily: 'Poppins', fontWeight: '600', fontWeight: '600', ml: -30 }}> Legal</Typography>
              <Stack direction="column" sx={{ alignItems: 'start', ml: isMatch1 ? -22 : -20 }}>
                <Button sx={{ color: '#fff', fontSize: '11px', fontFamily: 'Poppins', lineHeight: '15px' }}>User License</Button>
                <Button sx={{ color: '#fff', fontSize: '11px', fontFamily: 'Poppins', lineHeight: '15px' }}>License Agreement</Button>
                <Button sx={{ color: '#fff', fontSize: '11px', fontFamily: 'Poppins', lineHeight: '15px' }}>Privacy Policy</Button>
              </Stack>
            </Stack>
          </Stack>
          <Box sx={{ fontSize: '30px', color: '#fff', marginTop: '-150px', ml: 4 }}>
            <Box component="img" src={line} alt="truck" width={'95%'} height={'5px'} />
          </Box>
        </Grid>




        <Grid container spacing={2}>

          <Stack direction="column" sx={{ alignItems: 'start', width: '324px', ml: 10, mt: -12 }}>
            <Typography sx={{ color: '#fff', fontSize: '15px', fontFamily: 'Poppins', fontWeight: '600' }}> Follow Us on</Typography>
            <Stack direction="row" sx={{ alignItems: 'start', ml: -1 }}>

              <Box component="img" src={facebook} alt="truck" width={'20px'} height={'20px'} padding={0.5} />
              <Box component="img" src={birdimg} alt="truck" width={'20px'} height={'20px'} padding={0.5} />
              <Box component="img" src={twitter} alt="truck" width={'20px'} height={'20px'} padding={0.5} />
              <Box component="img" src={instagram} alt="truck" width={'20px'} height={'20px'} padding={0.5} />
            </Stack>
          </Stack>


          <Stack direction="column" sx={{ alignItems: 'center', mt: -11.7 }}>
            <Typography sx={{ color: '#fff', marginLeft: '210px', fontSize: '15px', fontFamily: 'Poppins', fontWeight: '600' }}> Flash&nbsp;App</Typography>
            <Stack direction="row" sx={{ margin: 0.5, ml: isMatch1 ? 28 : 30 }}>

              <a href="https://play.google.com/store/apps/details?id=com.dlxlogistics.app">
                <Button style={{ width: '10px', height: '21px', borderRadius: 5, }}

                  startIcon={<img src={googleplay} width={'75px'} height={'21px'} />}
                /></a>

              <Button style={{ width: '71px', height: '21px', marginLeft: '10px', borderRadius: 5 }}
                variant="text"
                startIcon={<img src={applestore} width={'75px'} height={'21px'} borderRadius={5} />}
              />

            </Stack></Stack>

          {/* <Stack direction="column" sx={{ alignItems: 'center', mt: -11.7 }}>
            <Typography sx={{ color: '#fff', marginLeft: '250px', fontSize: '15px', fontFamily: 'Poppins', fontWeight: '600' }}> Flash&nbsp;App</Typography>
            <Stack direction="row" sx={{  margin: 0.5, ml: -4 }}>
              <a href="https://play.google.com/store/apps/details?id=com.dlxlogistics.app">
                <Button style={{ width: '10px', height: '21px', borderRadius: 5, marginLeft: '250px' }}
                 
                  startIcon={<img src={googleplay} width={'75px'} height={'21px'} />}
                /></a>

              <Button style={{ width: '71px', height: '21px', marginLeft: '10px', borderRadius: 5 }}
                variant="text"
                startIcon={<img src={applestore} width={'75px'} height={'21px'} borderRadius={5} />}
              />

            </Stack></Stack> */}


        </Grid>
      </Stack>



      <Stack sx={{ backgroundColor: '#230585', justifyContent: 'center', paddingY: isMatch1 ? '10px' : '15px', marginTop: isMatch ? 0 : -3 }}>
        <Typography
          sx={{ fontSize: isMatch ? '15px' : '10px', fontFamily: 'Poppins', color: '#fff', textAlign: 'center', }} >
          © 2024 DLX All rights are reserved
        </Typography>

      </Stack>


    </ >
  )
}

export default Footer
