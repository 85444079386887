import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Divider, useMediaQuery } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
const ResponsiveCard = ({ title, address, phone, email, state }) => (

  <Card sx={{ backgroundColor: '#f7f7f7', color: '#202020', p: 0, width: '100%', height: '70%', borderRadius: '5px' }}>
    <Typography sx={{ fontSize: { xs: '12px', md: '20px' }, fontWeight: '600', marginLeft: { xs: '-20px', md: '-60px' }, width: '130%', display: "flex", backgroundColor: '#301375', color: '#fff', height: '50px', alignItems: 'center', justifyContent: "center" }}>
      <LocationOnIcon />
      &emsp;{title}
    </Typography>
    <CardContent>
      <Typography sx={{ fontSize: { xs: '12px', md: '18px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: 0.5 }}> {address}</Typography>
      {/* <Typography sx={{ fontSize: { xs: '12px', md: '35px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: '10px' }}></Typography> */}
      <Typography sx={{ fontSize: { xs: '12px', md: '18px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: 0.5 }}>  {phone}</Typography>
      <Typography sx={{ fontSize: { xs: '12px', md: '18px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: 0.5 }}>{email}</Typography>
      <Typography sx={{ fontSize: { xs: '12px', md: '18px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: 0.5 }}> {state}</Typography>
      {/*  <Typography sx={{ fontSize: { xs: '12px', md: '18px' }, fontFamily: 'Inter', fontWeight: '700', marginTop: '10px' }}> {email}</Typography>
      <Typography sx={{ fontSize: { xs: '12px', md: '15px' }, fontFamily: 'Inter', fontWeight: '100', marginTop: '10px' }}></Typography> */}
    </CardContent>
  </Card>
);


function Contact() {
  const isMatch = useMediaQuery("(min-width:650px)")
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} sm={12} lg={4}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          marginTop={{ xs: '10px', md: '50px' }}
          marginLeft={{ xs: '0px', md: '110px' }}
          marginRight={{ xs: 'auto', md: 'auto' }}
          height={isMatch ? '256px' : '200px'}
          width={isMatch ? '360px' : '280px'}
        >
          <ResponsiveCard
            title="NORTH  & EAST ZONE"
            address="OPP: Big Bazar,Near Shanidev Mandir,"
            phone="Kankaria Road,Raipur Gate"
            email="Ahmedabad - 22;"
            state=""
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          marginTop={{ xs: '-50px', md: '50px' }}
          marginLeft={{ xs: '0px', md: '60px' }}
          marginRight={{ xs: 'auto', md: 'auto' }}
          height={isMatch ? '256px' : '200px'}
          width={isMatch ? '360px' : '280px'}
        >
          <ResponsiveCard
            title="WEST ZONE"
            address="No -5, Harman Complex,"
            phone="Opp- Gupta Weigh Bridge,"
            email="Purna Village,Bhiwandi TK"
            state="Thane Dist"

          // address="No -5, Harman Complex,Opp- Gupta Weigh Bridge,
          // Purna Village,
          // Bhiwandi TK
          // ,Thane Dist"
          // phone="7517777200"
          // email="DLX@gmail.com"
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack
          direction={{ xs: 'column', md: 'row', lg: 'row' }}
          justifyContent="center"
          alignItems="center"
          marginTop={{ xs: '-50px', md: '50px' }}
          marginLeft={{ xs: '0px', md: '10px' }}
          marginRight={{ xs: 'auto', md: '100px' }}
          height={isMatch ? '256px' : '200px'}
          width={isMatch ? '350px' : '280px'}
        >
          <ResponsiveCard
            title="SOUTH ZONE"
            address="6/2818-B, Elayarasanenthal Main Road,
            Kovilpatti - 628501"
          // phone="7517777200"
          // email="DLX@gmail.com"
          />
        </Stack>
      </Grid>


      <Stack direction={isMatch ? 'row' : 'column'} >
        <Stack marginLeft={isMatch ? '200px' : '20px'} marginTop={isMatch ? '50px' : '20px'} marginBottom={isMatch ? '10px' : '0'}>
          <Typography sx={{ fontSize: isMatch ? '25px' : '14px', fontFamily: isMatch ? 'Poppins' : 'Poppins', fontWeight: isMatch ? '500' : '500' }}>Customer Care<Typography style={{ display: 'inline-block' }} sx={{ fontSize: isMatch ? 20 : 10, fontFamily: 'Poppins' }}>(24x7) </Typography> </Typography><br />
          <Typography sx={{ fontSize: isMatch ? '20px' : '12px', fontFamily: isMatch ? 'Poppins' : 'Inter', fontWeight: isMatch ? '400' : '500' }}><LocalPhoneIcon style={{ fontSize: isMatch ? 20 : 13 }}></LocalPhoneIcon>&nbsp;&nbsp;Phone no - <Typography style={{ display: 'inline-block' }} sx={{ color: "#5822DE", fontSize: isMatch ? '20px' : '12px', fontFamily: isMatch ? 'Poppins' : 'Inter', fontWeight: isMatch ? '400' : '500' }}>+91 75 17 77 71 00 ,+91 75 17 77 72 00 </Typography> </Typography>
          <Typography sx={{ fontSize: isMatch ? '20px' : '12px', fontFamily: isMatch ? 'Poppins' : 'Inter', fontWeight: isMatch ? '400' : '500' }}><MailOutlineIcon style={{ fontSize: isMatch ? 20 : 13 }}></MailOutlineIcon>&nbsp;&nbsp;Mail Id&emsp;&nbsp; - <Typography style={{ display: 'inline-block' }} sx={{ color: "#5822DE", fontSize: isMatch ? '20px' : '12px', fontFamily: isMatch ? 'Poppins' : 'Inter', fontWeight: isMatch ? '400' : '500' }}>helpdesk@dlxlogistics.in</Typography> </Typography>
        </Stack>
        <Divider style={{ marginLeft: isMatch ? 100 : 20, marginTop: isMatch ? 50 : 10 }} orientation={isMatch ? "vertical" : "horizontal"} variant="middle" flexItem />
        <Stack marginLeft={isMatch ? '100px' : '20px'} marginTop={isMatch ? '50px' : '10px'} marginBottom={isMatch ? '10px' : '0'}>
          <Typography sx={{ fontSize: isMatch ? '25px' : '14px', fontFamily: isMatch ? 'Poppins' : 'Poppins', fontWeight: isMatch ? '500' : '500' }}><b>For POD Payment </b><Typography style={{ display: 'inline-block' }} sx={{ fontSize: isMatch ? 16 : 8, fontFamily: 'Poppins' }}>(Office Hour 10:00am to 6:00 pm) </Typography></Typography><br />
          <Typography sx={{ fontSize: isMatch ? '20px' : '12px', fontFamily: isMatch ? 'Poppins' : 'Inter', fontWeight: isMatch ? '400' : '500' }}><LocalPhoneIcon style={{ fontSize: isMatch ? 20 : 13 }}></LocalPhoneIcon>&nbsp;&nbsp;Phone no&nbsp; - <Typography style={{ display: 'inline-block' }} sx={{ color: "#5822DE", fontSize: isMatch ? '20px' : '12px', fontFamily: isMatch ? 'Poppins' : 'Inter', fontWeight: isMatch ? '400' : '500' }}>7517777600 </Typography> </Typography>
          <Typography sx={{ fontSize: isMatch ? '20px' : '12px', fontFamily: isMatch ? 'Poppins' : 'Inter', fontWeight: isMatch ? '400' : '500' }}><MailOutlineIcon style={{ fontSize: isMatch ? 20 : 13 }}></MailOutlineIcon>&nbsp;&nbsp;Mail Id&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - <Typography style={{ display: 'inline-block' }} sx={{ color: "#5822DE", fontSize: isMatch ? '20px' : '12px', fontFamily: isMatch ? 'Poppins' : 'Inter', fontWeight: isMatch ? '400' : '500' }}>pod@dlxlogistics.in</Typography> </Typography>
        </Stack>
      </Stack>
    </Grid>

  );
}
export default Contact;

