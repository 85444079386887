import React, { useRef } from "react";
import Slider from "react-slick";

import mobhead1 from '../assets/images/mobilehead1.png';
import mobhead2 from '../assets/images/mobilehead2.png';
import mobhead3 from '../assets/images/mobilehead3.png';
import { Stack, useMediaQuery } from "@mui/material";
import headimg1 from '../assets/images/Headerhomeimg1.png';
import headimg2 from '../assets/images/Headerhomeimg2.png';
import headimg3 from '../assets/images/Headerhomeimg3.png';
function HeaderHomepage() {

  const isMatch = useMediaQuery("(min-width:650px)")


  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };
  const settings1 = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };
  return (
    <div  >
      <Stack display={{ xs: "none",sm:'flex', lg: "flex", md: "flex" }}  >
        <Slider  {...settings}>

          <div>
            <img src={headimg1} style={{width:"100%"}}/>
          </div>
          <div>
            <img src={headimg2} style={{width:"100%"}}/>
          </div>
          <div>
            <img src={headimg3} style={{width:"100%"}}/>
          </div>
        </Slider>
      </Stack>

      <Stack display={{ lg: "none", md: "none",sm:'none' }} >
        <Slider  {...settings1}>
          <div>
            <img src={mobhead3} />
          </div>
          <div>
            <img src={mobhead1} />
          </div>
          <div>
            <img src={mobhead2} />
          </div>

        </Slider>
      </Stack>

    </div>
  );
}
export default HeaderHomepage;
