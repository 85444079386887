import React, { useState } from 'react';
import { Stack, ToggleButton, ToggleButtonGroup, Typography, List, ListItem, ListItemIcon} from '@mui/material';
import consignor from '../assets/images/consigNew.png';
import TruckOpr from '../assets/images/truckoperatorhome.png';
import pointers from '../assets/svg/pointers.svg';

function Benefit() {
  const [value, setValue] = useState('consignee');

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderParagraph = () => {
    const commonContentStyles = {
      fontFamily: 'Poppins',
      marginLeft: '5%',
      marginRight: '5%',
      maxWidth: '40rem', // Adjust the maximum width as needed
      width: '90%', // Adjust the width as needed
    };

    return (
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        marginTop={{ xs: '50px', md: '0' }}
        alignItems="center"
        marginLeft={"14rem"}
        fontFamily={"Poppins"}
       // fontSize={50}
        fontWeight={900}

      >
        <img 
          src={value === 'consignee' ? consignor : TruckOpr}
          alt={value === 'consignee' ? 'Consignee/Consignor' : 'Truck Operator'}
          width={{ xs: '100%', md: '50%' }}
          height="400vh"

        />
        <Stack {...commonContentStyles} width={{ xs: '100%', md: '50%' }}>
          <Typography sx={{ color: '#5C21DB', fontSize: '30px',fontFamily:'Poppins',fontWeight: '500',marginLeft:'20px' }}>Benefit of</Typography>
          <Typography sx={{ fontSize: '28px', fontWeight: '500',marginLeft:'20px' ,fontFamily:'Poppins', }}>
            {value === 'consignee' ? 'CONSIGNOR/CONSIGNEE:' : 'TRUCK OPERATORS:'}
          </Typography>
          <List>
            <List>
              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'21.66px'} height={'21.66px'} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', marginRight: '50px' }}>
                  {value === 'consignee' ? (
                    <><b><u>Faster Delivery</u></b> with real-time tracking</>
                  ) : (
                    <><b><u>Reduced Idle Time: </u></b>Minimize idle time and increase earnings.</>
                  )}
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'21.66px'} height={'21.66px'} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', marginRight: '50px' }}>
                  {value === 'consignee' ? (
                    <> <b><u>Cost Savings</u></b> through competitive pricing & to optimize routes</>) :
                    (<><b><u>Best Payment Policy</u></b> Timely and transparent payment policy.</>)}
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'21.66px'} height={'21.66px'} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', marginRight: '50px' }}>
                  {value === 'consignee' ? (
                    <><b><u>Insured shipments </u></b>for reduced risk</>) : (<><b><u>Relay Load: </u></b>Maximize efficiency with relay load options.</>)}</Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'21.66px'} height={'21.66px'} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', marginRight: '50px' }}>
                  {value === 'consignee' ? (<><b><u>100% verified trucks </u></b>network for safe and reliable transportation</>) : (<><b><u>Verified Customer: </u></b>Guaranteed safe and hassle free-mindset.</>)}</Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'21.66px'} height={'21.66px'} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', marginRight: '50px' }}>
                  {value === 'consignee' ?
                    (<> <b><u>Improved customer service </u></b> by enabling faster & meet individual needs.</>) : (<> <b><u>Reliable Support: </u></b>24/7 customer service for smooth operations.</>)}
                </Typography>
              </ListItem>
            </List>
          </List>

        </Stack>
      </Stack>
    );
  };

  return (
    <>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleValueChange}
        aria-label="toggle buttons group"
        sx={{
          marginBottom: '20px',
        }}
      >
        {/* ToggleButton styles are adjusted for responsiveness */}
        <ToggleButton
          value="consignee"
          sx={{
            width: '53%', // Full width on small screens
            height: 'auto',
            fontSize: '20px',
            marginLeft: "6.25rem",
            fontWeight:"semibold",
            color: value === 'consignee' ? 'white' : 'black',
            backgroundColor: value === 'consignee' ? '#5611DF !important' : 'white',
            '&:hover': {
              backgroundColor: value === 'consignee' ? '#5611DF !important' : '#F5F5F5',
            },
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: '#5611DF !important',
              '&:hover': {
                backgroundColor: '#5611DF !important',
              },
            },
          }}
        >
          Consignor
        </ToggleButton>
        {/* ToggleButton styles are adjusted for responsiveness */}
        <ToggleButton
          value="truck-operator"
          sx={{
            width: '50%', // Full width on small screens
            height: 'auto',
            fontSize: '20px',
            marginRight: '50px',
            fontWeight:600,
            color: value === 'truck-operator' ? 'white' : 'black',
            backgroundColor: value === 'truck-operator' ? '#5611DF !important' : 'white',
            '&:hover': {
              backgroundColor: value === 'truck-operator' ? '#5611DF !important' : '#F5F5F5',
            },
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: '#5611DF !important',
              '&:hover': {
                backgroundColor: '#5611DF !important',
              },
            },
          }}
        >
          Truck Operator
        </ToggleButton>
      </ToggleButtonGroup>
      {renderParagraph()}
    </>
  );
}

export default Benefit;